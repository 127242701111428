import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import MuiLink from "./utility/MuiLink";

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(4)
  }
}));

export default function WhoWeAreSection(props) {
  const {title, description, button,...rest} = props;

  const classes = useStyles();


  return (
    <Box className={classes.root} {...rest}>
      <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
        <Box style={{maxWidth: 1200, marginBottom: 48, color: '#fff'}}>
          <Typography variant='h3' style={{marginBottom: 16, textAlign: 'center'}}>
            {title}
          </Typography>
          <Typography variant='h5' style={{textAlign: 'center'}}>
            {description}
          </Typography>
        </Box>
        <Button
          to='/contact-us'
          style={{padding: 24, backgroundColor: '#fff'}}
          component={MuiLink}
          underline={'none'}
          disableElevation
          variant='contained'>
          <Typography variant='subtitle1' color='primary' style={{fontWeight: 700}}>
            {button}
          </Typography>
        </Button>
      </Box>
    </Box>
  );
}
