import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Carousel from "./Carousel";
import Typography from "@material-ui/core/Typography";
import {chunk} from 'lodash';
import IconComponent from "./IconComponent";
import {navigate} from "../../.cache/gatsby-browser-entry";
import useBreakpoint from "./utility/useBreakpoint";

const useStyles = makeStyles(theme => ({
  root: {
    height: theme.spacing(77),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  mainContainer: {
    maxWidth: 1234,
    width: '100%'
  }
}));

export default function CarouselSection(props) {
  const {data, title, ...rest} = props;

  const classes = useStyles();

  const carouselItems = data.product_deals_group

  const isMobile = useBreakpoint('xs', false)

  const chunkedCarouselItems = chunk(carouselItems, 3)

  return (
    <Box px={4} className={classes.root} {...rest}>
      <Box className={classes.mainContainer}>
        <Typography variant={'h4'}>
          {title}
        </Typography>
        <Carousel style={{width: '100%'}} height='400px'>
          {isMobile ? carouselItems.map((item, index) => {
              const itemFinal = item.product_deals.document.data
              return (
                <Box display={'flex'} alignItems={'center'} width={'100%'} height={'100%'} key={index}>
                  <IconComponent
                    image={itemFinal.image && itemFinal.image.url}
                    text={itemFinal.title}
                    key={index}
                    style={{maxWidth: 395, height: 'unset'}}
                    handleClick={() => navigate('/deals')}
                  />
                </Box>
              )
            })
            : chunkedCarouselItems.map((itemList, index) => {
              return (
                <Box display={'flex'} justifyContent={'space-evenly'} alignItems={'center'} width={'100%'}
                     height={'100%'}
                     key={index}>
                  {itemList.map((item, index) => {
                    const itemFinal = item.product_deals.document.data
                    return (
                      <IconComponent
                        image={itemFinal.image && itemFinal.image.url}
                        text={itemFinal.title}
                        key={index}
                        imageHeight={285}
                        style={{maxWidth: 395, height: 285}}
                        handleClick={() => navigate('/deals')}
                      />
                    )
                  })}
                </Box>
              )
            })}
        </Carousel>
      </Box>
    </Box>
  );
}
