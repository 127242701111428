import React, {cloneElement, useState} from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import Box from "@material-ui/core/Box";

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  carouselContainer: {
    width: '100%'
  },
  buttonContainer: {
    width: '100%',
  },
  button: {
    minWidth: "unset",
    width: theme.spacing(4)
  }
}));

export default function Carousel(props) {
  const {children, height, ...rest} = props;
  const classes = useStyles();
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = children ? children.length : 0;

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleStepChange = step => {
    setActiveStep(step);
  };

  return (
    <Box display={'flex'} flexDirection={'column'} alignItems={'center'} className={classes.root} {...rest}>
      <Box className={classes.buttonContainer} display={'flex'} justifyContent='flex-end'>
        <Button size="small" onClick={handleBack} disabled={activeStep === 0} className={classes.button}>
          {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </Button>
        <Button size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1} className={classes.button}>
          {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </Button>
      </Box>
      <Box display={'flex'} className={classes.carouselContainer}>
        <SwipeableViews
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={activeStep}
          style={{width: '100%'}}
          containerStyle={{height: height}}
          onChangeIndex={handleStepChange}
          enableMouseEvents
        >
          {children && React.Children.map(children, (child, index) => (
            <Box height={height} display={'flex'} justifyContent={'center'} key={child}>
              {Math.abs(activeStep - index) <= 2 ? (
                cloneElement(child)
              ) : null}
            </Box>
          ))}
        </SwipeableViews>
      </Box>
    </Box>

  );
}
