import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Typography} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Brightness1Icon from "@material-ui/icons/Brightness1";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: theme.spacing(38)
  },
  image: {
    height: '100%',
    width: '100%',
    objectFit: 'cover'
  },
}));

export default function IconComponent({image, text, height, width, imageHeight, imageWidth, handleClick, ...rest}) {
  const classes = useStyles();

  return (
    <Box className={classes.root} {...rest}>
      <Button onClick={() => handleClick()} style={{width: '100%'}}>
        <img src={image} alt='' className={classes.image} style={{height: imageHeight, width: imageWidth}}/>
      </Button>
      {text && <Typography variant={"body2"} style={{paddingLeft: 8}}>
        <Brightness1Icon style={{fontSize: 12, marginRight: 8}} color='primary'/>
        {text}
      </Typography>}
    </Box>
  );
}
