import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import CarouselHome from "./CarouselHome";
import Box from "@material-ui/core/Box";
import useBreakpoint from "./utility/useBreakpoint";

const useStyles = makeStyles(theme => ({
  image: {
    height: `calc(100vh - 126px)`,
  },
  imageMobile: {
    width: '100%',
    height: `calc(100vh - 126px)`,
    objectFit: 'contain'
  },
}));

export default function HomeLandingSection(props) {
  const {images, ...rest} = props;

  const classes = useStyles();

  const isMobile = useBreakpoint('xs', false)

  return (
    <Box style={{height: `calc(100vh - 96px)`}} {...rest}>
      <CarouselHome style={{width: '100%'}} steps={images}>
        {images && images.map((image, index) => {
          return (
            <img src={image.homepage_image.url} alt='' className={isMobile ?  classes.imageMobile : classes.image} key={index}/>
          )
        })}
      </CarouselHome>
    </Box>
  )
}
