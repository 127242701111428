import React from "react";
import SEO from "../components/utility/SEO";
import {graphql} from "gatsby";
import WhoWeAreSection from "../components/WhoWeAreSection";
import HomeLandingSection from "../components/HomeLandingSection";
import CarouselSection from "../components/CarouselSection";

const IndexPage = ({data}) => {
  if (!data || !data.prismicHomePage) {
    return ''
  }

  const {data: pageData} = data.prismicHomePage
  const {data: pageDataDeals} = data.prismicDeals

  return (
    <>
      <SEO title="Αρχική"/>
      <HomeLandingSection images={pageData.homepage_images_group}/>
      <CarouselSection data={pageDataDeals} title={pageData.carousel_title}/>
      <WhoWeAreSection title={pageData.whoweare_title} description={pageData.whoweare_description} button={pageData.whoweare_button_text}/>
    </>
  )
}

export default IndexPage;

export const HomePageQuery = graphql`
  query HomePage {
    prismicHomePage {
      data {
        homepage_images_group {
          homepage_image {
            url
          }
        }
        carousel_title
        whoweare_title
        whoweare_description
        whoweare_button_text
      }
    }
    prismicDeals {
      data {
        product_deals_group {
          product_deals {
            document {
              ... on PrismicProduct {
                id
                data {
                  title
                  category
                  image {
                    url
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`