import React, {cloneElement, useState} from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import SwipeableViews from 'react-swipeable-views';
import Box from "@material-ui/core/Box";
import CircleIcon from '@material-ui/icons/FiberManualRecord';
import autoPlay from "react-swipeable-views-utils/lib/autoPlay";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100%'
  },
  carouselContainer: {
    width: '100%',
    height: '100%'
  },
  buttonContainer: {
    width: '100%',
  },
  button: {
    minWidth: "unset",
    width: theme.spacing(4)
  }
}));

const CarouselHome = ({children, steps, ...rest}) => {
  const classes = useStyles();
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);

  const handleStepChange = step => {
    setActiveStep(step);
  };

  return (
    <Box display={'flex'} flexDirection={'column'} alignItems={'center'} className={classes.root} {...rest}>
      <Box display={'flex'} className={classes.carouselContainer}>
        <AutoPlaySwipeableViews
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={activeStep}
          style={{width: '100%'}}
          containerStyle={{height: '100%'}}
          onChangeIndex={handleStepChange}
          enableMouseEvents
        >
          {children && React.Children.map(children, (child, index) => (
            <Box height={'100%'} display={'flex'} justifyContent={'center'} key={child}>
              {Math.abs(activeStep - index) <= 2 ? (
                cloneElement(child)
              ) : null}
            </Box>
          ))}
        </AutoPlaySwipeableViews>
      </Box>
        <Box style={{width: '100%', display: 'flex',justifyContent: 'center', alignItems: 'center', padding: 8}}>
          {steps.map((step, index) => {
            return (
              <CircleIcon
                style={{height: 14, width: 14, opacity: index === activeStep ? 1 : 0.5, cursor: 'pointer'}}
                onClick={() => handleStepChange(index)}
                key={index}/>
            )
          })}
        </Box>
    </Box>
  );
}

export default CarouselHome
